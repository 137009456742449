<template>
  <div class="index_second">
    <div class="bg_white" :class="{ bg_white_animation: Animation }"></div>
    <video
      autoplay
      muted="muted"
      loop
      controls="false"
      controlsList="nodownload"
      ref="my_video"
      class="pc_bg_video"
    >
      <!-- :class="{
        pc_top_video_animation: topAnimation,
        pc_low_video_animation: lowAnimation,
      }" -->
      <source src="../assets/video/15min.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
    <div class="pc">
      <div
        class="left"
        :class="{ left_animation: Animation, is_disappear: !Animation }"
      >
        <img src="~img/indexImg/007.png" alt="" />
        <div class="text">
          <h2>安谋科技新业务品牌</h2>
          <!-- <p>创芯生，赋未来</p> -->
        </div>
      </div>
      <div
        class="right"
        :class="{ right_animation: Animation, is_disappear: !Animation }"
      >
        <div class="fenge"></div>
        <p>
          “核芯动力”的使命是打造开放的智能数据流融合计算平台，赋能中国智能计算产业把握超域架构计算时代新机遇，推动中国本土XPU生态的创新，树立全球XPU标准标杆。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "second",
  props: ["index", "w"],
  data() {
    return {
      Animation: false,
      topAnimation: false,
      lowAnimation: false,
      pc: undefined,
      ipad: undefined
    };
  },
  created() {
    if (this.w >= 1366) {
      this.pc = true;
      this.ipad = false;
    } else if (this.w < 1366) {
      this.pc = false;
      this.ipad = true;
    }
    if (this.index == 1) {
      this.Animation = true;
    } else {
      this.Animation = false;
    }
  },
  watch: {
    index: function(index, oldindex) {
      if (index != 1) {
        // 不在当前这个层 移除动画类
        this.lowAnimation = false;
        this.topAnimation = false;
        this.Animation = false;
      } else {
        this.Animation = true;
      }
      if (index == 1 && index > oldindex) {
        this.topAnimation = true;
      } else if (index == 1 && index < oldindex) {
        this.lowAnimation = true;
      }
      // console.log("上", this.topAnimation, "下", this.lowAnimation);
    },
    w: function(w) {
      if (w >= 1366) {
        this.pc = true;
        this.ipad = false;
      } else if (w < 1366) {
        this.pc = false;
        this.ipad = true;
      }
    }
  },
  mounted() {
    this.$refs.my_video["disablePictureInPicture"] = true;
    this.video = this.$refs.xc_video;
  }
};
</script>


<style lang="less" scoped>
.index_second {
  background: #000;
  .pc {
    width: 100%;
    height: 100vh;
    position: relative;
    background: url("~img/indexImg/002.png") no-repeat center center;
    background-size: cover;
    top: 0;
    left: 0;
    .left {
      position: absolute;
      left: 0.8rem /* 134/100 */;
      width: 100%;
      top: 200vh;
      top: 50%;
      transform: translate(0, -50%);
      opacity: 0;
      img {
        width: 2.47rem;
        height: 2.69rem /* 269/100 */;
      }
      .text {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 3.1rem /* 310/100 */;
        h2 {
          font-size: 0.9rem /* 90/100 */;
          font-weight: 400;
          color: #ffffff;
        }
        p {
          font-size: 0.62rem /* 62/100 */;
          font-weight: 400;
          color: #00b3d6;
        }
      }
    }
    .left_animation {
      animation: topleft 1s 0.5s linear alternate forwards;
    }
    .is_disappear {
      animation: disappear 0.5s linear alternate forwards;
    }
    @keyframes topleft {
      0% {
        top: 90%;
        opacity: 0;
      }
      50% {
        top: 70%;
        opacity: 0.5;
      }
      100% {
        top: 50%;
        transform: translate(0, -50%);
        opacity: 1;
      }
    }
    @keyframes disappear {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
      }
    }
    .right {
      width: 523px /* 523/100 */;
      position: absolute;
      right: 0.8rem;
      bottom: 10.9% /* 109/100 */;
      opacity: 0;
      .fenge {
        width: 236px;
        height: 3px;
        background: #00b3d6;
      }
      p {
        margin-top: 0.27rem /* 27/100 */;
        font-size: 16px;
        line-height: 31px;
        color: #fff;
      }
    }
    .right_animation {
      animation: topright 1s 0.5s linear alternate forwards;
    }
    @keyframes topright {
      from {
        opacity: 0;
        bottom: -200px;
      }
      to {
        opacity: 1;
        bottom: 10.9% /* 109/100 */;
      }
    }
  }
  .pc_bg_video {
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .pc_top_video_animation {
    animation: topbig 1s 1s linear alternate;
  }
  .pc_low_video_animation {
    animation: lowbig 1s 1s linear alternate;
  }
  // 背景动画
  @keyframes topbig {
    from {
      top: 200vh;
    }
    to {
      top: 0;
    }
  }
  @keyframes lowbig {
    from {
      top: -200vh;
    }
    to {
      top: 0;
    }
  }
  .pc_bg_video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-play-button {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-timeline {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-current-time-display {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-time-remaining-display {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-mute-button {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  .pc_bg_video::-webkit-media-controls-volume-slider {
    display: none;
  }
}
</style>