<template>
  <div class="web-first">
    <img src="~img/indexImg/1_1.3b3e3d58.gif" class="web_bg_img" />
    <h1 :class="{ h1_animation: Animation }">
      中国智能计算生态领航者
      <div class="fenge"></div>
    </h1>
    <div class="web-jieshao" :class="{ jieshao_animation: Animation }">
      <h4>坚持以自研业务技术创新与Arm技术授权相配合</h4>
      <p>
        安谋科技立足本土创新，坚持以自研业务技术创新与Arm技术授权相配合，为中国集成电路产业提供丰富的产品组合和解决方案，赋能中国智能计算“芯” 生态。
      </p>
      <!-- <p>
        在超域架构计算时代，安谋科技坚持以自主架构XPU的创新发展与CPU IP相配合，提供多样化、定制化、符合中国产业及市场需求的融合计算平台。
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'webFirst',
  props: ['webIndex'],
  data() {
    return {
      Animation: true,
    }
  },
  watch: {
    webIndex: function(webIndex) {
      if (this.webIndex == 0) {
        this.Animation = true
      } else {
        this.Animation = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.web-first {
  height: 100vh;
  width: 100%;
  position: relative;
  .web_bg_img {
    height: 100vh;
  }
  h1 {
    font-size: 2.025rem /* 81/40 */;
    color: #fff;
    position: absolute;
    top: 25% /* 394/40 */;
    left: -23rem /* 51/40 */;
    transition: 0.5s all;
    .fenge {
      width: 130px;
      height: 1px;
      background: #898989;
      position: absolute;
      bottom: -34.5px;
      left: 0 /* 51/40 */;
    }
  }

  .h1_animation {
    left: 1.275rem /* 51/40 */;
  }
  .web-jieshao {
    width: 17rem /* 646/40 */;
    height: 152px;
    position: absolute;
    bottom: -8rem /* 69/40 */;
    left: 50%;
    transform: translate(-50%);
    transition: 0.5s all;
    h4 {
      font-size: 0.8rem /* 20/40 */;
      color: #00b3d6;
      margin-bottom: 0.25rem /* 10/40 */;
    }
    p {
      font-size: 11px;
      line-height: 20px;
      color: #c8c8c8;
      margin-top: 10px;
    }
  }
  .jieshao_animation {
    bottom: 65px;
  }
}
</style>
