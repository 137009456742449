<template>
  <div class="index_first">
    <video autoplay muted="muted" loop controls="false" controlsList="nodownload" ref="my_video">
      <source src="../assets/video/8min.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video>
    <div class="pc" v-if="pc">
      <h1 :class="{ h1_animation: Animation }">中国智能计算生态领航者</h1>
      <div class="pc_fenge"></div>
      <div class="jianjie" :class="{ j_animation: Animation }">
        <h2>坚持以自研业务技术创新与Arm技术授权相配合</h2>
        <div class="ptext">
          <p>
            安谋科技立足本土创新，坚持以自研业务技术创新与Arm技术授权相配合，为中国集成电路产业提供丰富的产品组合和解决方案，赋能中国智能计算“芯”
            生态。
          </p>
          <!-- <p>
            在超域架构计算时代，安谋科技坚持以自主架构XPU的创新发展与CPU
            IP相配合，提供多样化、定制化、符合中国产业及市场需求的融合计算平台。
          </p> -->
        </div>
      </div>
    </div>
    <div class="ipad" v-if="ipad">
      <h1 class="ipad_h1">中国智能计算生态领航者</h1>
      <div class="ipad_fenge"></div>
      <div class="ipad_jianjie">
        <h2>坚持以自研业务技术创新与Arm技术授权相配合</h2>
        <div class="ptext">
          <p>
            安谋科技立足本土创新，坚持以自研业务技术创新与Arm技术授权相配合，为中国集成电路产业提供丰富的产品组合和解决方案，赋能中国智能计算芯生态。
          </p>
          <!-- <p>
            在超域架构计算时代，安谋科技坚持以自主架构XPU的创新发展与CPU
            IP相配合，提供多样化、定制化、符合中国产业及市场需求的融合计算平台。
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'first',
  props: ['index', 'w'],
  data() {
    return {
      Animation: false,
      pc: undefined,
      ipad: undefined,
      showInfon: true,
    }
  },
  created() {
    if (this.w >= 1366) {
      this.pc = true
      this.ipad = false
    } else if (this.w < 1366) {
      this.pc = false
      this.ipad = true
    }
  },
  mounted() {
    this.$refs.my_video['disablePictureInPicture'] = true
    let tiem
    clearTimeout(tiem)
    tiem = setTimeout(() => {
      this.Animation = true
    }, 10)
  },
  watch: {
    index: function(index, oldindex) {
      if (index == 0) {
        this.Animation = true
      } else {
        this.Animation = false
      }
    },
    w: function(w) {
      // console.log(w);
      if (w >= 1366) {
        this.pc = true
        this.ipad = false
      } else if (w < 1366) {
        this.pc = false
        this.ipad = true
      }
    },
  },
  methods: {
    goNotice() {
      window.location.href =
        'https://www.armchina.com/infodetails?id=33&istop=1&istitle=%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83&name=%E5%AE%89%E8%B0%8B%E7%A7%91%E6%8A%80%E5%9B%A2%E9%98%9F%E5%85%AC%E5%BC%80%E4%BF%A1'
    },
  },
}
</script>

<style lang="less" scoped>
.index_first {
  .ipad {
    .ipad_h1 {
      position: absolute;
      color: #fff;
      width: 1200px;
      font-size: 74px;
      left: 90px;
      top: 237px;
    }
    .ipad_fenge {
      width: 250px;
      height: 2px;
      background-color: #fff;
      opacity: 0.5;
      position: absolute;
      left: 90px;
      top: 390px;
    }
    .ipad_jianjie {
      width: 372px;
      position: absolute;
      right: 84px;
      bottom: 44px;
      // top: 674px;
      h2 {
        color: #00b3d6;
        font-size: 16px;
        margin-bottom: 21px;
      }
      p {
        line-height: 24px;
        font-size: 14px;
        color: #c8c8c8;
      }
    }
  }
  .pc {
    h1 {
      position: absolute;
      top: 31%;
      left: -11rem /* 137/100 */;
      font-size: 1rem;
      font-weight: 400;
      color: #ffffff;
      transition: 1s all;
    }
    .h1_animation {
      left: 0.78rem /* 137/100 */;
    }
    .pc_fenge {
      width: 4.46rem /* 446/100 */;
      height: 0.03rem /* 3/100 */;
      background-color: #ffffff;
      opacity: 0.5;
      position: absolute;
      top: 50.91%;
      left: 0.8rem /* 137/100 */;
    }
    .jianjie {
      width: 532px;
      height: 235px;
      position: absolute;
      right: -5rem /* 80/100 */;
      bottom: 10.9%;
      transition: 2s all;
      h2 {
        font-size: 20px;
        color: #00b3d6;
      }
      .ptext {
        margin-top: 32px;
        p {
          font-size: 16px;
          color: #c8c8c8;
          line-height: 31px;
          font-weight: 400;
          text-shadow: 0px 4px 8px #000000;
        }
      }
    }
    .j_animation {
      right: 0.8rem /* 80/100 */;
    }
  }

  video {
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: fill;
    // height: auto;
    // width: auto;
    // min-width: 100%;
    // min-height: 100%;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  video::-webkit-media-controls-play-button {
    display: none;
  }
  video::-webkit-media-controls-timeline {
    display: none;
  }
  video::-webkit-media-controls-current-time-display {
    display: none;
  }
  video::-webkit-media-controls-time-remaining-display {
    display: none;
  }
  video::-webkit-media-controls-mute-button {
    display: none;
  }
  video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  video::-webkit-media-controls-volume-slider {
    display: none;
  }
}
</style>
