<template>
  <div class="index">
    <!-- pc -->
    <div class="pc" v-if="show">
      <!-- 滚动,小鼠标 -->
      <navig :only="only" :w="w" :navlist="navlist" />

      <div class="The_scroll_bar">
        <div class="progress">
          <div class="module_one" :style="style"></div>
        </div>
        <p>{{ number }}</p>
        <img src="~img/indexImg/005.png" alt="" />
      </div>
      <el-carousel direction="vertical" :autoplay="false" :loop="false" ref="pc_carousel">
        <el-carousel-item>
          <div class="pc_one" ref="scrollOne">
            <pc-first :index="index" :w="w" />
          </div>
        </el-carousel-item>
        <!-- <el-carousel-item>
          <div class="pc_two" ref="scrollTwo">
            <pc-second :index="index" :w="w" />
          </div>
        </el-carousel-item> -->
        <el-carousel-item :style="styleindex">
          <div class="pc_three">
            <pc-Three @get="get" :index="index" :w="w" />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="pc_three" ref="scrollThree">
            <pc-thrid :index="index" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <pc-footer :showFooter="showFooter" />
      <!-- <div class="information" v-if="showInfon">
        <div class="infobox">
          <img src="../assets/img/indexImg/35.png">
          <img src="../assets/img/indexImg/34.png" class="close" @click="showInfon = false">
          <div class="btn" @click="goNotice"></div>
        </div>
      </div> -->
    </div>

    <!-- web -->
    <div class="mobile" v-if="!show">
      <web-nav :navlist="navlist" />
      <van-swipe style="height: 100vh" vertical :loop="false" @change="onChange" ref="webswipe">
        <van-swipe-item>
          <webFirst :webIndex="webIndex" />
        </van-swipe-item>
        <!-- <van-swipe-item>
          <web-second :webIndex="webIndex" />
        </van-swipe-item> -->
        <van-swipe-item>
          <web-three :webIndex="webIndex" />
        </van-swipe-item>
        <van-swipe-item>
          <web-third :webIndex="webIndex" />
        </van-swipe-item>
        <van-swipe-item>
          <web-footer :webIndex="webIndex" />
        </van-swipe-item>
      </van-swipe>
      <img src="~img/indexImg/020.png" class="up-img" @click="webUp" v-if="webIndex != 0" />
      <!-- <div class="information" v-if="showInfon">
        <div class="infobox">
          <img src="../assets/img/indexImg/35.png">
          <img src="../assets/img/indexImg/34.png" class="close" @click="showInfon = false">
          <div class="btn" @click="goNotice"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import navig from '@/components/navigation.vue' // pc 导航
import webNav from '@/components/webNav.vue' // web 导航
import pcFirst from '@/assembly/indexFirst.vue' // pc 第一屏
import pcSecond from '@/assembly/indexSecond.vue' // pc 第二屏
import pcThree from '@/assembly/indexThree.vue' // pc 第三屏
import pcThrid from '@/assembly/indexThird.vue' // pc 第四屏
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webFirst from '@/assembly/web/webFirst.vue' // web 第一屏
import webSecond from '@/assembly/web/webSecond.vue' // web 第二屏
import webThree from '@/assembly/web/webThree.vue' // web 第三屏
import webThird from '@/assembly/web/webThird.vue' // web 第四屏
import webFooter from '@/assembly/web/webFooter.vue' // web 第五屏
export default {
  components: {
    navig,
    webNav,
    pcFirst,
    pcSecond,
    pcThree,
    pcThrid,
    pcFooter,
    webFirst,
    webSecond,
    webThree,
    webThird,
    webFooter,
  },
  data() {
    return {
      w: undefined, // 屏幕尺寸
      number: '01', // 小鼠标
      initialIndex: 0,
      num: 0,
      index: 0,
      showFooter: false,
      equipment: '',
      only: false,
      webIndex: 0,
      swipe: null,
      style: '',
      screenWidth: '',
      navlist: '',
      styleindex: '',
      showInfon: true,
      timeout: '',
      flag: true,
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    if (this.os().isTablet) {
      this.ipad = true
    } else {
      this.ipad = false
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
    if (from.name == 'brief') {
      if (location.href.indexOf('#reloaded') == -1) {
        location.href = location.href + '#reloaded'
        location.reload()
      }
    }
  },
  mounted() {
    // console.log(this.$store.state.previousRouter.name);
    this.getToken()
    // window.addEventListener("scroll", this.handleScroll);
    this.w = document.body.clientWidth // 页面一进来获取 视口宽度
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
      })()
    }
    if (this.equipment == 'pc') {
      window.addEventListener('mousewheel', this.handleScroll)
      this.only = true
    } else if (this.equipment == 'web') {
      this.swipe = this.$refs.webswipe
    }
    if (this.ipad) {
      this.slideBanner()
    }
    document.title = '安谋科技-中国智能计算生态领航者'
  },
  destroyed() {
    // document.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('mousewheel', this.handleScroll)
  },
  watch: {
    w: function(w) {
      if (this.w <= 750) {
        // console.log(this.w, "web");
      } else if (this.w > 750) {
        // console.log(this.w, "pc");
        if (this.w <= 1024) {
          // console.log("ipad 竖屏");
        }
      }
    },
  },
  computed: {
    show() {
      let that = this
      window.onresize = function() {
        that.w = document.documentElement.offsetWidth || document.body.offsetWidth
      }
      if (this.w <= 750) {
        this.equipment = 'web'
        return false
      } else {
        this.equipment = 'pc'
        return true
      }
    },
  },
  methods: {
    goNotice() {
      window.location.href =
        'https://www.armchina.com/infodetails?id=33&istop=1&istitle=%E6%96%B0%E9%97%BB%E4%B8%AD%E5%BF%83&name=%E5%AE%89%E8%B0%8B%E7%A7%91%E6%8A%80%E5%9B%A2%E9%98%9F%E5%85%AC%E5%BC%80%E4%BF%A1'
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container')
      var height = box.offsetWidth
      var startPoint = 0
      var stopPoint = 0
      // //重置坐标
      var resetPoint = function() {
        startPoint = 0
        stopPoint = 0
      }
      //手指按下
      box.addEventListener('touchstart', function(e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageY
      })
      //手指滑动
      box.addEventListener('touchmove', function(e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageY
      })
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener('touchend', function(e) {
        // console.log(Math.abs(startPoint - stopPoint));
        if (stopPoint != 0 && height - startPoint < height - stopPoint) {
          if (startPoint - stopPoint > 100) {
            if (that.initialIndex >= 2) {
              that.initialIndex = 3
              that.showFooter = true
            } else {
              that.initialIndex += 1
              that.setActiveItem(that.initialIndex)
              that.showFooter = false
            }
          }
          resetPoint()
        } else if (stopPoint != 0 && height - startPoint > height - stopPoint) {
          if (Math.abs(startPoint - stopPoint) > 100) {
            that.showFooter = false
            if (that.initialIndex <= 0) {
              that.initialIndex = 0
            } else {
              that.initialIndex -= 1
              that.setActiveItem(that.initialIndex)
            }
          }
          resetPoint()
        }
      })
    },
    os() {
      var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian
      return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc,
      }
    },
    webUp() {
      this.swipe.swipeTo(0)
    },
    falv() {
      location.href = 'https://www.armchina.com/trademarksGuidelines.html'
    },
    onChange(index) {
      this.webIndex = index
    },
    setActiveItem(index) {
      this.index = index
      if (index == 0) {
        this.style = 'top:0%'
        this.number = '01'
      } else if (index == 1) {
        this.style = 'top:35%'
        this.number = '02'
      } else if (index == 2) {
        this.style = 'top:75%'
        this.number = '03'
      }
      // else if (index == 3) {
      //   this.style = "top:75%";
      //   this.number = "04";
      // }
      let nextIndex = index++
      this.$refs.pc_carousel.setActiveItem(nextIndex)
    },
    handleScroll(e) {
      if (this.equipment == 'pc' && this.os().isPc) {
        // pc 执行
        // this.num++;
        // if (this.num > 4) {
        // this.num = 0;
        // if (this.timeout) clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          console.log(e.wheelDelta)
          if (e.wheelDelta == -120) {
            if (this.initialIndex >= 3) {
              this.initialIndex = 3
              this.showFooter = true
            } else {
              this.initialIndex += 1
              this.setActiveItem(this.initialIndex)
              this.showFooter = false
            }
          }
          if (e.wheelDelta == 120) {
            this.showFooter = false
            if (this.initialIndex <= 0) {
              this.initialIndex = 0
              // this.setActiveItem(0)
            } else {
              this.initialIndex -= 1
              this.setActiveItem(this.initialIndex)
            }
          }
        } else {
          console.log(e.deltaY)
          let direction = e.deltaY > 0 ? 'down' : 'up' //deltaY为正则滚轮向下，为负滚轮向上
          if (direction == 'down' && e.deltaY >= 40) {
            console.log(this.initialIndex)
            if (this.initialIndex >= 3) {
              this.initialIndex = 3
              this.showFooter = true
            } else if (this.flag) {
              this.initialIndex = this.initialIndex + 1
              this.setActiveItem(this.initialIndex)
              this.showFooter = false
              this.flag = false
              this.timeout = setTimeout(() => {
                clearTimeout(this.timeout)
                this.flag = true
              }, 200)
            }
          }
          if (direction == 'up' && e.deltaY <= -40) {
            this.showFooter = false
            if (this.initialIndex <= 0) {
              this.initialIndex = 0
              // this.setActiveItem(0)
            } else if (this.flag) {
              this.initialIndex -= 1
              this.setActiveItem(this.initialIndex)
              this.flag = false
              this.timeout = setTimeout(() => {
                clearTimeout(this.timeout)
                this.flag = true
              }, 200)
            }
          }
        }
        // }, 100);

        // }
      }
    },
    async getToken() {
      const res = await this.axios.post('/v1/api/getToken', {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      console.log(res)
      if (res.code == 200) {
        localStorage.setItem('token', res.data) // 存储token
        this.getTags()
      } else {
      }
    },
    async getTags() {
      const res = await this.axios.get('/v1/api/index_tags')
      if (res.code == '00') {
        localStorage.setItem('nav', JSON.stringify(res.data))
        this.navlist = res.data
      }
    },
    get(play) {
      console.log(play, '触发了')
      if (!play) {
        this.styleindex = 'z-index:999'
      } else {
        this.styleindex = ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
.index {
  .pc {
    overflow: hidden;
    margin: auto;
    background-color: #0c0c0c;
    .The_scroll_bar {
      width: 0.36rem /* 36/100 */;
      height: 2.88rem /* 288/100 */;
      position: fixed;
      right: 0.31rem /* 31/100 */;
      bottom: 0;
      z-index: 9;
      .progress {
        height: 1.56rem /* 156/100 */;
        width: 2px /* 2/100 */;
        background-color: #ffffff;
        opacity: 0.5;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        .module_one {
          width: 2px;
          height: 25%;
          background-color: #00a0c8;
          position: absolute;
          transition: 0.4s all;
        }
      }
      p {
        font-size: 0.14rem /* 14/100 */;
        color: #ffffff;
        position: absolute;
        left: 50%;
        bottom: 1rem;
        transform: translate(-50%);
      }
      img {
        width: 0.23rem /* 23/100 */;
        position: absolute;
        left: 50%;
        bottom: 0.36rem /* 36/100 */;
        transform: translate(-50%);
      }
    }
    /deep/.el-carousel__container {
      height: 100vh;
    }
    /deep/.el-carousel__indicators {
      display: none;
    }
    /deep/.el-carousel__item.is-animating {
      -webkit-transition: -webkit-transform 1s ease-in-out;
      transition: -webkit-transform 1s ease-in-out;
      transition: transform 1s ease-in-out;
      transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
    }
    .pc_one {
      min-width: 100%;
      min-height: 100%;
      overflow: hidden;
      position: relative;
    }
    .pc_two {
      min-width: 100%;
      min-height: 100%;
      overflow: hidden;
      position: relative;
      background: #fff;
    }
    .pc_three {
      min-width: 100%;
      min-height: 100%;
      // background: url("~img/indexImg/003.png") no-repeat;
      // background-size: 100% 100%;
      position: relative;
    }
    .pc_footer {
      height: 4rem /* 400/100 */;
      width: 100%;
      position: relative;
      background-color: #0c0c0c;
      display: none;
      .pc_logo {
        width: 1.91rem /* 191/100 */;
        position: absolute;
        top: 0.64rem /* 64/100 */;
        left: 1.32rem /* 132/100 */;
      }
      .ul_title {
        position: absolute;
        top: 0.64rem /* 64/100 */;
        left: 4.88rem /* 488/100 */;
        .pc_title {
          cursor: pointer;
          float: left;
          margin-right: 0.98rem /* 98/100 */;
          color: #fff;
          font-size: 0.2rem /* 20/100 */;
          .pc_content {
            // list-style: none;
            font-size: 0.16rem /* 16/100 */;
            color: #777777;
            line-height: 0.4rem /* 40/100 */;
            cursor: pointer;
          }
        }
      }
      .erweima {
        width: 1.28rem /* 128/100 */;
        position: absolute;
        top: 1.04rem /* 104/100 */;
        right: 1.49rem /* 149/100 */;
      }
      h4 {
        font-size: 0.16rem /* 16/100 */;
        color: #fff;
        position: absolute;
        top: 2.51rem /* 251/100 */;
        right: 1.65rem /* 165/100 */;
      }
      .pc_footitex {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 0.77rem /* 77/100 */;
        background-color: #070707;
        text-align: center;
        line-height: 0.77rem /* 77/100 */;
        color: #777777;
      }
    }
    .pc_showfooter {
      display: block;
    }
    .information {
      width: 100%;
      height: 100vh;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      z-index: 9999;

      .infobox {
        width: 481px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .close {
          width: 40px;
          position: absolute;
          top: 10px;
          right: 20px;
          cursor: pointer;
        }
        .btn {
          width: 300px;
          height: 50px;
          position: absolute;
          bottom: 35px;
          left: 50%;
          transform: translate(-50%);
          cursor: pointer;
        }
      }
    }
  }
  .mobile {
    margin: 0 auto;
    height: 100vh;
    background-color: #010601;
    overflow: hidden;
    .information {
      width: 100%;
      height: 100vh;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      z-index: 9999;
      .infobox {
        width: 75%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .close {
          width: 20px;
          position: absolute;
          top: 10px;
          right: 20px;
          cursor: pointer;
        }
        .btn {
          width: 200px;
          height: 50px;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translate(-50%);
          // background: hotpink;
          cursor: pointer;
        }
      }
    }
    .up-img {
      width: 1.775rem /* 71/40 */;
      height: 1.775rem /* 71/40 */;
      position: fixed;
      right: 0.95rem /* 38/40 */;
      bottom: 0.85rem /* 34/40 */;
    }
    /deep/.van-swipe__indicators--vertical {
      display: none;
    }
    /deep/.web-footer {
      .botn {
        margin-top: 60px;
      }
    }
  }
}
</style>
