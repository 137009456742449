<template>
  <div class="web-second">
    <img src="~img/indexImg/1_3.c24254b8.gif" class="web_bg_img" />
    <div class="shi-logo"></div>
    <img src="~img/indexImg/007.png" class="web-logo" />
    <div class="web-text">
      <h3 :class="{ h3_animation: Animation }">安谋科技新业务品牌</h3>
      <!-- <h4 :class="{ h4_animation: Animation }">创芯生，赋未来</h4> -->
      <div class="web-fenge" :class="{ fenge_animation: Animation }"></div>
      <p :class="{ p_animation: Animation }">
        “核芯动力”的使命是打造开放的智能数据流融合计算平台，赋能中国智能计算产业把握超域架构计算时代新机遇，推动中国本土XPU生态的创新，树立全球XPU标准标杆。
      </p>
    </div>
    <!-- :class="{ video_animation: Animation }" -->
    <!-- <video
      controls="false"
      class="xuan_video"
      ref="xc_video"
      
    >
      <source src="~@/assets/video/ARM_0322大档~4.mp4" type="video/mp4" />
      您的浏览器不支持Video标签。
    </video> -->
  </div>
</template>


<script>
export default {
  name: "webSecond",
  props: ["webIndex"],
  data() {
    return {
      Animation: false
    };
  },
  watch: {
    webIndex: function(webIndex) {
      if (this.webIndex == 1) {
        this.Animation = true;
      } else {
        this.Animation = false;
      }
    }
  }
};
</script>


<style lang="less" scoped>
.web-second {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .shi-logo {
    width: 100%;
    height: 100%;
    background: url("~img/indexImg/29.png") no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 0;
  }
  .web_bg_img {
    height: 100vh;
  }
  .web-logo {
    width: 6rem /* 240/40 */;
    position: absolute;
    top: 6.25rem /* 250/40 */;
    left: 50%;
    transform: translate(-50%);
  }
  .web-text {
    width: 16.65rem /* 666/40 */;
    height: 5.25rem /* 210/40 */;
    position: absolute;
    left: 50%;
    top: 14.325rem /* 573/40 */;
    transform: translate(-50%);
    h3 {
      font-size: 1.5rem /* 60/40 */;
      color: #fff;
    }
    h4 {
      font-size: 1rem /* 51/40 */;
      color: #00b3d6;
    }
    .web-fenge {
      width: 5.375rem /* 215/40 */;
      height: 2px;
      background-color: #00b3d6;
      margin-top: 3.425rem /* 137/40 */;
    }
    p {
      font-size: 13px;
      letter-spacing: 2px;
      color: #c8c8c8;
      line-height: 20px;
      margin-top: 0.975rem /* 39/40 */;
    }
  }
  .xuan_video {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.5s 0.2s all;
  }
  .video_animation {
    left: 0;
  }
}
</style>

